#nps-div {
  margin: 100px auto;
  max-width: 800px;
  text-align: center;
}

#nps-h2 {
  font-size: 30px;
  text-align: center;
  margin: 0;
}

#nps-info {
  max-width: 30%;
  color: lightgray;
}

@media (max-width: 640px) {
  #nps-div {
    margin: 40px auto;
  }
}
